* { outline: none; }
body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  background-color: rgb(245, 245, 247);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*card tag tooltip*/
.tag-tooltip {
  max-width: 400px;
  padding: 0 !important;
  height: auto;
  border-radius: 10px !important;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff !important;
  opacity: 100 !important;
}

/*TODO: style taglist scroll
*::-webkit-scrollbar {
  width: 15px;
  position: absolute;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(95, 108, 118, 0.3);
  opacity: 30%;
  width: 5px;
  height: 73px;
  border-radius: 4px;
  border-right: 10px inherit solid;
  padding-right: 10px;
  margin-right: 10px;
}
*/

/*browser autofill bugfix*/
@keyframes onAutoFillStart {}
@keyframes onAutoFillCancel {}
input[type=password]:-webkit-autofill, input[type=email]:-webkit-autofill {
  animation-name: onAutoFillStart;
  /*transition: background-color 1s ease-in-out 0s;*/
}
input[type=password]:not(:-webkit-autofill), input[type=email]:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

/*pie progress*/
.circle-out {
  position:relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ccc;
  overflow: hidden;
}
.circle-in {
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 1px;
  border-radius: 50%;
  background: white;
}
.progress {
  height: 55px;
  width: 110px;
  background: blue;
  position: absolute;
  top:-55px;
  left: -30px;
  transform: rotate(30deg);
  transform-origin: 55px 55px;
  transition: all .3s;
}
/*rounded progress*/
.container {
  position: absolute;
  width: 50px;
  height: 50px;
}
.clipper {
  width: 50%;
  height: 100%;
  right: 0;
  position: absolute;
  overflow: hidden;
  transform-origin: left center;
  animation: cliprotate 3000ms steps(2) infinite;
  -webkit-animation: cliprotate 3000ms steps(2) infinite;
}
.bow {
  box-sizing: border-box;
  height: 100%;
  right: 0;
  position: absolute;
  border: solid 1px transparent;
  border-top-color: blue;
  border-left-color: blue;
  border-radius: 50%;
}
.rt {
  width: 200%;
  animation: rotate 1500ms linear infinite;
  -webkit-animation: rotate 1500ms linear infinite;
}
.st {
  width: 100%;
  transform: rotate(135deg);
  animation: showfixed 3000ms steps(2) infinite;
  -webkit-animation: showfixed 3000ms linear infinite;
}
@keyframes cliprotate {
  0% {transform: rotate(180deg);}
  100% {transform: rotate(-180deg);}
}
@keyframes rotate {
  0% {transform: rotate(135deg);}
  100% {transform: rotate(-45deg);}
}
@keyframes showfixed {
  0% {opacity: 1;}
  24.9% {opacity: 1;}
  25% {opacity: 1;}
  49.9% {opacity: 1;}
  50% {opacity: 0;}
  74.9% {opacity: 0;}
  75% {opacity: 0;}
  100% {opacity: 0;}
}
/*
@-webkit-keyframes cliprotate {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
@-webkit-keyframes rotate {
  0% {transform: rotate(-45deg);}
  100% {transform: rotate(135deg);}
}
@-webkit-keyframes showfixed {
  0% {opacity: 0;}
  49.9% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 1;}
}
*/
